import { InjectionToken } from '@angular/core';
import { AppointmentApiService } from './appointment-api.service';

export const HTTP_APPOINTMENT_SERVICE =
  new InjectionToken<AppointmentApiService>('AppointmentApiService');

export const APPOINTMENT_PROVIDER = {
  provide: HTTP_APPOINTMENT_SERVICE,
  useClass: AppointmentApiService,
};
