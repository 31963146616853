import { inject, Injectable } from '@angular/core';
import { IApointmentApiService } from './appointment-api.interface';
import { map, Observable, take } from 'rxjs';
import {
  Appointment,
  AppointmentCreateOrUpdate,
} from '../domain/appointment.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AppointmentApiModel } from './appointment-api.model';

@Injectable({
  providedIn: 'root',
})
export class AppointmentApiService implements IApointmentApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  getAppointments({
    customerId,
    employeeId,
    startDate,
    endDate,
  }: {
    customerId?: number;
    employeeId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<Appointment[]> {
    const url = `${this.BASE_URL}appointments?customer=${
      customerId ?? ''
    }&employee=${employeeId ?? ''}&start_date=${startDate ?? ''}&end_date=${
      endDate ?? ''
    }`;
    const headers = new HttpHeaders();
    return this._http.get<AppointmentApiModel[]>(url, { headers }).pipe(
      take(1),
      map((appointments) =>
        appointments.map((appointment: Appointment) => appointment)
      )
    );
  }

  createAppointment(
    appointment: AppointmentCreateOrUpdate
  ): Observable<Appointment> {
    const url = `${this.BASE_URL}appointments`;
    const headers = new HttpHeaders();
    return this._http
      .post<AppointmentApiModel>(url, appointment, { headers })
      .pipe(
        take(1),
        map((appointment) => appointment)
      );
  }

  updateAppointment(
    id: number,
    appointment: AppointmentCreateOrUpdate
  ): Observable<Appointment> {
    const url = `${this.BASE_URL}appointments/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .put<AppointmentApiModel>(url, appointment, { headers })
      .pipe(
        take(1),
        map((appointment) => appointment)
      );
  }

  deleteAppointment(id: number): Observable<void> {
    const url = `${this.BASE_URL}appointments/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<void>(url, { headers });
  }
}
